"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransparentUpgradeableProxy__factory = exports.ProxyAdmin__factory = void 0;
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
var ProxyAdmin__factory_1 = require("./ProxyAdmin__factory");
Object.defineProperty(exports, "ProxyAdmin__factory", { enumerable: true, get: function () { return ProxyAdmin__factory_1.ProxyAdmin__factory; } });
var TransparentUpgradeableProxy__factory_1 = require("./TransparentUpgradeableProxy__factory");
Object.defineProperty(exports, "TransparentUpgradeableProxy__factory", { enumerable: true, get: function () { return TransparentUpgradeableProxy__factory_1.TransparentUpgradeableProxy__factory; } });
