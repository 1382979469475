"use strict";
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.IMojosDescriptor__factory = void 0;
const ethers_1 = require("ethers");
const _abi = [
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "string",
                name: "baseURI",
                type: "string",
            },
        ],
        name: "BaseURIUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "bool",
                name: "enabled",
                type: "bool",
            },
        ],
        name: "DataURIToggled",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [],
        name: "PartsLocked",
        type: "event",
    },
    {
        inputs: [
            {
                internalType: "string",
                name: "background",
                type: "string",
            },
        ],
        name: "addBackground",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes",
                name: "body",
                type: "bytes",
            },
        ],
        name: "addBody",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes",
                name: "bodyAccessory",
                type: "bytes",
            },
        ],
        name: "addBodyAccessory",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint8",
                name: "paletteIndex",
                type: "uint8",
            },
            {
                internalType: "string",
                name: "color",
                type: "string",
            },
        ],
        name: "addColorToPalette",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes",
                name: "face",
                type: "bytes",
            },
        ],
        name: "addFace",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes",
                name: "headAccessory",
                type: "bytes",
            },
        ],
        name: "addHeadAccessory",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "string[]",
                name: "backgrounds",
                type: "string[]",
            },
        ],
        name: "addManyBackgrounds",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes[]",
                name: "bodies",
                type: "bytes[]",
            },
        ],
        name: "addManyBodies",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes[]",
                name: "bodyAccessories",
                type: "bytes[]",
            },
        ],
        name: "addManyBodyAccessories",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint8",
                name: "paletteIndex",
                type: "uint8",
            },
            {
                internalType: "string[]",
                name: "newColors",
                type: "string[]",
            },
        ],
        name: "addManyColorsToPalette",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes[]",
                name: "faces",
                type: "bytes[]",
            },
        ],
        name: "addManyFaces",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes[]",
                name: "headAccessories",
                type: "bytes[]",
            },
        ],
        name: "addManyHeadAccessories",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "arePartsLocked",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "backgroundCount",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "index",
                type: "uint256",
            },
        ],
        name: "backgrounds",
        outputs: [
            {
                internalType: "string",
                name: "",
                type: "string",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "baseURI",
        outputs: [
            {
                internalType: "string",
                name: "",
                type: "string",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "index",
                type: "uint256",
            },
        ],
        name: "bodies",
        outputs: [
            {
                internalType: "bytes",
                name: "",
                type: "bytes",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "index",
                type: "uint256",
            },
        ],
        name: "bodyAccessories",
        outputs: [
            {
                internalType: "bytes",
                name: "",
                type: "bytes",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "bodyAccessoryCount",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "bodyCount",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
            {
                components: [
                    {
                        internalType: "uint48",
                        name: "background",
                        type: "uint48",
                    },
                    {
                        internalType: "uint48",
                        name: "body",
                        type: "uint48",
                    },
                    {
                        internalType: "uint48",
                        name: "bodyAccessory",
                        type: "uint48",
                    },
                    {
                        internalType: "uint48",
                        name: "face",
                        type: "uint48",
                    },
                    {
                        internalType: "uint48",
                        name: "headAccessory",
                        type: "uint48",
                    },
                ],
                internalType: "struct IMojosSeeder.Seed",
                name: "seed",
                type: "tuple",
            },
        ],
        name: "dataURI",
        outputs: [
            {
                internalType: "string",
                name: "",
                type: "string",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "faceCount",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "index",
                type: "uint256",
            },
        ],
        name: "faces",
        outputs: [
            {
                internalType: "bytes",
                name: "",
                type: "bytes",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                components: [
                    {
                        internalType: "uint48",
                        name: "background",
                        type: "uint48",
                    },
                    {
                        internalType: "uint48",
                        name: "body",
                        type: "uint48",
                    },
                    {
                        internalType: "uint48",
                        name: "bodyAccessory",
                        type: "uint48",
                    },
                    {
                        internalType: "uint48",
                        name: "face",
                        type: "uint48",
                    },
                    {
                        internalType: "uint48",
                        name: "headAccessory",
                        type: "uint48",
                    },
                ],
                internalType: "struct IMojosSeeder.Seed",
                name: "seed",
                type: "tuple",
            },
        ],
        name: "generateSVGImage",
        outputs: [
            {
                internalType: "string",
                name: "",
                type: "string",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "string",
                name: "name",
                type: "string",
            },
            {
                internalType: "string",
                name: "description",
                type: "string",
            },
            {
                components: [
                    {
                        internalType: "uint48",
                        name: "background",
                        type: "uint48",
                    },
                    {
                        internalType: "uint48",
                        name: "body",
                        type: "uint48",
                    },
                    {
                        internalType: "uint48",
                        name: "bodyAccessory",
                        type: "uint48",
                    },
                    {
                        internalType: "uint48",
                        name: "face",
                        type: "uint48",
                    },
                    {
                        internalType: "uint48",
                        name: "headAccessory",
                        type: "uint48",
                    },
                ],
                internalType: "struct IMojosSeeder.Seed",
                name: "seed",
                type: "tuple",
            },
        ],
        name: "genericDataURI",
        outputs: [
            {
                internalType: "string",
                name: "",
                type: "string",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "index",
                type: "uint256",
            },
        ],
        name: "headAccessories",
        outputs: [
            {
                internalType: "bytes",
                name: "",
                type: "bytes",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "headAccessoryCount",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "isDataURIEnabled",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "lockParts",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint8",
                name: "paletteIndex",
                type: "uint8",
            },
            {
                internalType: "uint256",
                name: "colorIndex",
                type: "uint256",
            },
        ],
        name: "palettes",
        outputs: [
            {
                internalType: "string",
                name: "",
                type: "string",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "string",
                name: "baseURI",
                type: "string",
            },
        ],
        name: "setBaseURI",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "toggleDataURIEnabled",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
            {
                components: [
                    {
                        internalType: "uint48",
                        name: "background",
                        type: "uint48",
                    },
                    {
                        internalType: "uint48",
                        name: "body",
                        type: "uint48",
                    },
                    {
                        internalType: "uint48",
                        name: "bodyAccessory",
                        type: "uint48",
                    },
                    {
                        internalType: "uint48",
                        name: "face",
                        type: "uint48",
                    },
                    {
                        internalType: "uint48",
                        name: "headAccessory",
                        type: "uint48",
                    },
                ],
                internalType: "struct IMojosSeeder.Seed",
                name: "seed",
                type: "tuple",
            },
        ],
        name: "tokenURI",
        outputs: [
            {
                internalType: "string",
                name: "",
                type: "string",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
];
class IMojosDescriptor__factory {
    static createInterface() {
        return new ethers_1.utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    }
}
exports.IMojosDescriptor__factory = IMojosDescriptor__factory;
IMojosDescriptor__factory.abi = _abi;
