"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MojosTokenLike__factory = exports.MojosDAOStorageV1__factory = exports.MojosDAOProxyStorage__factory = exports.MojosDAOEvents__factory = exports.IMojosDAOExecutor__factory = void 0;
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
var IMojosDAOExecutor__factory_1 = require("./IMojosDAOExecutor__factory");
Object.defineProperty(exports, "IMojosDAOExecutor__factory", { enumerable: true, get: function () { return IMojosDAOExecutor__factory_1.IMojosDAOExecutor__factory; } });
var MojosDAOEvents__factory_1 = require("./MojosDAOEvents__factory");
Object.defineProperty(exports, "MojosDAOEvents__factory", { enumerable: true, get: function () { return MojosDAOEvents__factory_1.MojosDAOEvents__factory; } });
var MojosDAOProxyStorage__factory_1 = require("./MojosDAOProxyStorage__factory");
Object.defineProperty(exports, "MojosDAOProxyStorage__factory", { enumerable: true, get: function () { return MojosDAOProxyStorage__factory_1.MojosDAOProxyStorage__factory; } });
var MojosDAOStorageV1__factory_1 = require("./MojosDAOStorageV1__factory");
Object.defineProperty(exports, "MojosDAOStorageV1__factory", { enumerable: true, get: function () { return MojosDAOStorageV1__factory_1.MojosDAOStorageV1__factory; } });
var MojosTokenLike__factory_1 = require("./MojosTokenLike__factory");
Object.defineProperty(exports, "MojosTokenLike__factory", { enumerable: true, get: function () { return MojosTokenLike__factory_1.MojosTokenLike__factory; } });
