"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IGovernorCompatibilityBravo__factory = exports.GovernorCompatibilityBravo__factory = void 0;
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
var GovernorCompatibilityBravo__factory_1 = require("./GovernorCompatibilityBravo__factory");
Object.defineProperty(exports, "GovernorCompatibilityBravo__factory", { enumerable: true, get: function () { return GovernorCompatibilityBravo__factory_1.GovernorCompatibilityBravo__factory; } });
var IGovernorCompatibilityBravo__factory_1 = require("./IGovernorCompatibilityBravo__factory");
Object.defineProperty(exports, "IGovernorCompatibilityBravo__factory", { enumerable: true, get: function () { return IGovernorCompatibilityBravo__factory_1.IGovernorCompatibilityBravo__factory; } });
