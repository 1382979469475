"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMojoSeedFromBlockHash = exports.getPseudorandomPart = exports.shiftRightAndCast = exports.getRandomMojoSeed = exports.getMojoData = void 0;
const solidity_1 = require("@ethersproject/solidity");
const bignumber_1 = require("@ethersproject/bignumber");
// import { images, bgcolors } from './image-data.json';
const encoded_layers_json_1 = require("./encoded-layers.json");
// const { bodies, accessories, heads, glasses } = images;
const [bodies, bodyAccessories, faces, headAccessories] = encoded_layers_json_1.parts;
/**
 * Get encoded part and background information using a Mojo seed
 * @param seed The Mojo seed
 */
const getMojoData = (seed) => {
    const bodies_data = bodies.map(({ data }) => data);
    return {
        parts: [
            bodies[seed.body],
            bodyAccessories[seed.bodyAccessory],
            faces[seed.face],
            headAccessories[seed.headAccessory],
        ],
        background: encoded_layers_json_1.bgcolors[seed.background],
    };
};
exports.getMojoData = getMojoData;
/**
 * Generate a random Mojo seed
 * @param seed The Mojo seed
 */
const getRandomMojoSeed = () => {
    return {
        background: Math.floor(Math.random() * encoded_layers_json_1.bgcolors.length),
        body: Math.floor(Math.random() * bodies.length),
        bodyAccessory: Math.floor(Math.random() * bodyAccessories.length),
        face: Math.floor(Math.random() * faces.length),
        headAccessory: Math.floor(Math.random() * headAccessories.length),
    };
};
exports.getRandomMojoSeed = getRandomMojoSeed;
/**
 * Emulate bitwise right shift and uint cast
 * @param value A Big Number
 * @param shiftAmount The amount to right shift
 * @param uintSize The uint bit size to cast to
 */
const shiftRightAndCast = (value, shiftAmount, uintSize) => {
    const shifted = bignumber_1.BigNumber.from(value).shr(shiftAmount).toHexString();
    return `0x${shifted.substring(shifted.length - uintSize / 4)}`;
};
exports.shiftRightAndCast = shiftRightAndCast;
/**
 * Emulates the MojosSeeder.sol methodology for pseudorandomly selecting a part
 * @param pseudorandomness Hex representation of a number
 * @param partCount The number of parts to pseudorandomly choose from
 * @param shiftAmount The amount to right shift
 * @param uintSize The size of the unsigned integer
 */
const getPseudorandomPart = (pseudorandomness, partCount, shiftAmount, uintSize = 48) => {
    const hex = (0, exports.shiftRightAndCast)(pseudorandomness, shiftAmount, uintSize);
    return bignumber_1.BigNumber.from(hex).mod(partCount).toNumber();
};
exports.getPseudorandomPart = getPseudorandomPart;
/**
 * Emulates the MojosSeeder.sol methodology for generating a Mojo seed
 * @param mojoId The Mojo tokenId used to create pseudorandomness
 * @param blockHash The block hash use to create pseudorandomness
 */
const getMojoSeedFromBlockHash = (mojoId, blockHash) => {
    const pseudorandomness = (0, solidity_1.keccak256)(['bytes32', 'uint256'], [blockHash, mojoId]);
    return {
        background: (0, exports.getPseudorandomPart)(pseudorandomness, encoded_layers_json_1.bgcolors.length, 0),
        body: (0, exports.getPseudorandomPart)(pseudorandomness, bodies.length, 48),
        bodyAccessory: (0, exports.getPseudorandomPart)(pseudorandomness, bodyAccessories.length, 96),
        face: (0, exports.getPseudorandomPart)(pseudorandomness, faces.length, 144),
        headAccessory: (0, exports.getPseudorandomPart)(pseudorandomness, headAccessories.length, 192),
    };
};
exports.getMojoSeedFromBlockHash = getMojoSeedFromBlockHash;
