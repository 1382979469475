"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ICompoundTimelock__factory = exports.GovernorTimelockCompound__factory = void 0;
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
var GovernorTimelockCompound__factory_1 = require("./GovernorTimelockCompound__factory");
Object.defineProperty(exports, "GovernorTimelockCompound__factory", { enumerable: true, get: function () { return GovernorTimelockCompound__factory_1.GovernorTimelockCompound__factory; } });
var ICompoundTimelock__factory_1 = require("./ICompoundTimelock__factory");
Object.defineProperty(exports, "ICompoundTimelock__factory", { enumerable: true, get: function () { return ICompoundTimelock__factory_1.ICompoundTimelock__factory; } });
