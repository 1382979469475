"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IMojosAuctionHouse__factory = exports.ILayerZeroUserApplicationConfig__factory = exports.ILayerZeroReceiver__factory = exports.ILayerZeroEndpoint__factory = exports.MojosDAOProxy__factory = exports.MojosDAOLogicV1__factory = exports.MojosTokenLike__factory = exports.MojosDAOStorageV1__factory = exports.MojosDAOProxyStorage__factory = exports.MojosDAOEvents__factory = exports.IMojosDAOExecutor__factory = exports.MojosDAOExecutor__factory = exports.MojoGovernor__factory = exports.IProxyRegistry__factory = exports.ERC721Enumerable__factory = exports.ERC721Checkpointable__factory = exports.ERC721__factory = exports.IERC165__factory = exports.ERC165__factory = exports.IERC721Receiver__factory = exports.IERC721__factory = exports.IERC721Metadata__factory = exports.IERC721Enumerable__factory = exports.IERC20__factory = exports.IERC20Metadata__factory = exports.ERC20Votes__factory = exports.IERC20Permit__factory = exports.ERC20Permit__factory = exports.ERC20__factory = exports.TransparentUpgradeableProxy__factory = exports.ProxyAdmin__factory = exports.Proxy__factory = exports.ERC1967Upgrade__factory = exports.ERC1967Proxy__factory = exports.IBeacon__factory = exports.IERC1822Proxiable__factory = exports.IVotes__factory = exports.IGovernor__factory = exports.Governor__factory = exports.IGovernorTimelock__factory = exports.GovernorVotesQuorumFraction__factory = exports.GovernorVotes__factory = exports.ICompoundTimelock__factory = exports.GovernorTimelockCompound__factory = exports.IGovernorCompatibilityBravo__factory = exports.GovernorCompatibilityBravo__factory = exports.Ownable__factory = exports.PausableUpgradeable__factory = exports.OwnableUpgradeable__factory = exports.factories = void 0;
exports.UniversalMojo__factory = exports.WETH__factory = exports.MojosDAOLogicV1Harness__factory = exports.MojosDAOImmutable__factory = exports.MojosDAOExecutorTest__factory = exports.MojosDAOExecutorHarness__factory = exports.Administered__factory = exports.MaliciousBidder__factory = exports.MojosAuctionHouseProxyAdmin__factory = exports.MojosAuctionHouseProxy__factory = exports.NonblockingLzApp__factory = exports.MojosToken__factory = exports.MojosSeeder__factory = exports.MojosDescriptor__factory = exports.MojosAuctionHouse__factory = exports.LzApp__factory = exports.IWETH__factory = exports.IMojosToken__factory = exports.IMojosSeeder__factory = exports.IMojosDescriptor__factory = void 0;
exports.factories = __importStar(require("./factories"));
var OwnableUpgradeable__factory_1 = require("./factories/@openzeppelin/contracts-upgradeable/access/OwnableUpgradeable__factory");
Object.defineProperty(exports, "OwnableUpgradeable__factory", { enumerable: true, get: function () { return OwnableUpgradeable__factory_1.OwnableUpgradeable__factory; } });
var PausableUpgradeable__factory_1 = require("./factories/@openzeppelin/contracts-upgradeable/security/PausableUpgradeable__factory");
Object.defineProperty(exports, "PausableUpgradeable__factory", { enumerable: true, get: function () { return PausableUpgradeable__factory_1.PausableUpgradeable__factory; } });
var Ownable__factory_1 = require("./factories/@openzeppelin/contracts/access/Ownable__factory");
Object.defineProperty(exports, "Ownable__factory", { enumerable: true, get: function () { return Ownable__factory_1.Ownable__factory; } });
var GovernorCompatibilityBravo__factory_1 = require("./factories/@openzeppelin/contracts/governance/compatibility/GovernorCompatibilityBravo__factory");
Object.defineProperty(exports, "GovernorCompatibilityBravo__factory", { enumerable: true, get: function () { return GovernorCompatibilityBravo__factory_1.GovernorCompatibilityBravo__factory; } });
var IGovernorCompatibilityBravo__factory_1 = require("./factories/@openzeppelin/contracts/governance/compatibility/IGovernorCompatibilityBravo__factory");
Object.defineProperty(exports, "IGovernorCompatibilityBravo__factory", { enumerable: true, get: function () { return IGovernorCompatibilityBravo__factory_1.IGovernorCompatibilityBravo__factory; } });
var GovernorTimelockCompound__factory_1 = require("./factories/@openzeppelin/contracts/governance/extensions/GovernorTimelockCompound.sol/GovernorTimelockCompound__factory");
Object.defineProperty(exports, "GovernorTimelockCompound__factory", { enumerable: true, get: function () { return GovernorTimelockCompound__factory_1.GovernorTimelockCompound__factory; } });
var ICompoundTimelock__factory_1 = require("./factories/@openzeppelin/contracts/governance/extensions/GovernorTimelockCompound.sol/ICompoundTimelock__factory");
Object.defineProperty(exports, "ICompoundTimelock__factory", { enumerable: true, get: function () { return ICompoundTimelock__factory_1.ICompoundTimelock__factory; } });
var GovernorVotes__factory_1 = require("./factories/@openzeppelin/contracts/governance/extensions/GovernorVotes__factory");
Object.defineProperty(exports, "GovernorVotes__factory", { enumerable: true, get: function () { return GovernorVotes__factory_1.GovernorVotes__factory; } });
var GovernorVotesQuorumFraction__factory_1 = require("./factories/@openzeppelin/contracts/governance/extensions/GovernorVotesQuorumFraction__factory");
Object.defineProperty(exports, "GovernorVotesQuorumFraction__factory", { enumerable: true, get: function () { return GovernorVotesQuorumFraction__factory_1.GovernorVotesQuorumFraction__factory; } });
var IGovernorTimelock__factory_1 = require("./factories/@openzeppelin/contracts/governance/extensions/IGovernorTimelock__factory");
Object.defineProperty(exports, "IGovernorTimelock__factory", { enumerable: true, get: function () { return IGovernorTimelock__factory_1.IGovernorTimelock__factory; } });
var Governor__factory_1 = require("./factories/@openzeppelin/contracts/governance/Governor__factory");
Object.defineProperty(exports, "Governor__factory", { enumerable: true, get: function () { return Governor__factory_1.Governor__factory; } });
var IGovernor__factory_1 = require("./factories/@openzeppelin/contracts/governance/IGovernor__factory");
Object.defineProperty(exports, "IGovernor__factory", { enumerable: true, get: function () { return IGovernor__factory_1.IGovernor__factory; } });
var IVotes__factory_1 = require("./factories/@openzeppelin/contracts/governance/utils/IVotes__factory");
Object.defineProperty(exports, "IVotes__factory", { enumerable: true, get: function () { return IVotes__factory_1.IVotes__factory; } });
var IERC1822Proxiable__factory_1 = require("./factories/@openzeppelin/contracts/interfaces/draft-IERC1822.sol/IERC1822Proxiable__factory");
Object.defineProperty(exports, "IERC1822Proxiable__factory", { enumerable: true, get: function () { return IERC1822Proxiable__factory_1.IERC1822Proxiable__factory; } });
var IBeacon__factory_1 = require("./factories/@openzeppelin/contracts/proxy/beacon/IBeacon__factory");
Object.defineProperty(exports, "IBeacon__factory", { enumerable: true, get: function () { return IBeacon__factory_1.IBeacon__factory; } });
var ERC1967Proxy__factory_1 = require("./factories/@openzeppelin/contracts/proxy/ERC1967/ERC1967Proxy__factory");
Object.defineProperty(exports, "ERC1967Proxy__factory", { enumerable: true, get: function () { return ERC1967Proxy__factory_1.ERC1967Proxy__factory; } });
var ERC1967Upgrade__factory_1 = require("./factories/@openzeppelin/contracts/proxy/ERC1967/ERC1967Upgrade__factory");
Object.defineProperty(exports, "ERC1967Upgrade__factory", { enumerable: true, get: function () { return ERC1967Upgrade__factory_1.ERC1967Upgrade__factory; } });
var Proxy__factory_1 = require("./factories/@openzeppelin/contracts/proxy/Proxy__factory");
Object.defineProperty(exports, "Proxy__factory", { enumerable: true, get: function () { return Proxy__factory_1.Proxy__factory; } });
var ProxyAdmin__factory_1 = require("./factories/@openzeppelin/contracts/proxy/transparent/ProxyAdmin__factory");
Object.defineProperty(exports, "ProxyAdmin__factory", { enumerable: true, get: function () { return ProxyAdmin__factory_1.ProxyAdmin__factory; } });
var TransparentUpgradeableProxy__factory_1 = require("./factories/@openzeppelin/contracts/proxy/transparent/TransparentUpgradeableProxy__factory");
Object.defineProperty(exports, "TransparentUpgradeableProxy__factory", { enumerable: true, get: function () { return TransparentUpgradeableProxy__factory_1.TransparentUpgradeableProxy__factory; } });
var ERC20__factory_1 = require("./factories/@openzeppelin/contracts/token/ERC20/ERC20__factory");
Object.defineProperty(exports, "ERC20__factory", { enumerable: true, get: function () { return ERC20__factory_1.ERC20__factory; } });
var ERC20Permit__factory_1 = require("./factories/@openzeppelin/contracts/token/ERC20/extensions/draft-ERC20Permit.sol/ERC20Permit__factory");
Object.defineProperty(exports, "ERC20Permit__factory", { enumerable: true, get: function () { return ERC20Permit__factory_1.ERC20Permit__factory; } });
var IERC20Permit__factory_1 = require("./factories/@openzeppelin/contracts/token/ERC20/extensions/draft-IERC20Permit.sol/IERC20Permit__factory");
Object.defineProperty(exports, "IERC20Permit__factory", { enumerable: true, get: function () { return IERC20Permit__factory_1.IERC20Permit__factory; } });
var ERC20Votes__factory_1 = require("./factories/@openzeppelin/contracts/token/ERC20/extensions/ERC20Votes__factory");
Object.defineProperty(exports, "ERC20Votes__factory", { enumerable: true, get: function () { return ERC20Votes__factory_1.ERC20Votes__factory; } });
var IERC20Metadata__factory_1 = require("./factories/@openzeppelin/contracts/token/ERC20/extensions/IERC20Metadata__factory");
Object.defineProperty(exports, "IERC20Metadata__factory", { enumerable: true, get: function () { return IERC20Metadata__factory_1.IERC20Metadata__factory; } });
var IERC20__factory_1 = require("./factories/@openzeppelin/contracts/token/ERC20/IERC20__factory");
Object.defineProperty(exports, "IERC20__factory", { enumerable: true, get: function () { return IERC20__factory_1.IERC20__factory; } });
var IERC721Enumerable__factory_1 = require("./factories/@openzeppelin/contracts/token/ERC721/extensions/IERC721Enumerable__factory");
Object.defineProperty(exports, "IERC721Enumerable__factory", { enumerable: true, get: function () { return IERC721Enumerable__factory_1.IERC721Enumerable__factory; } });
var IERC721Metadata__factory_1 = require("./factories/@openzeppelin/contracts/token/ERC721/extensions/IERC721Metadata__factory");
Object.defineProperty(exports, "IERC721Metadata__factory", { enumerable: true, get: function () { return IERC721Metadata__factory_1.IERC721Metadata__factory; } });
var IERC721__factory_1 = require("./factories/@openzeppelin/contracts/token/ERC721/IERC721__factory");
Object.defineProperty(exports, "IERC721__factory", { enumerable: true, get: function () { return IERC721__factory_1.IERC721__factory; } });
var IERC721Receiver__factory_1 = require("./factories/@openzeppelin/contracts/token/ERC721/IERC721Receiver__factory");
Object.defineProperty(exports, "IERC721Receiver__factory", { enumerable: true, get: function () { return IERC721Receiver__factory_1.IERC721Receiver__factory; } });
var ERC165__factory_1 = require("./factories/@openzeppelin/contracts/utils/introspection/ERC165__factory");
Object.defineProperty(exports, "ERC165__factory", { enumerable: true, get: function () { return ERC165__factory_1.ERC165__factory; } });
var IERC165__factory_1 = require("./factories/@openzeppelin/contracts/utils/introspection/IERC165__factory");
Object.defineProperty(exports, "IERC165__factory", { enumerable: true, get: function () { return IERC165__factory_1.IERC165__factory; } });
var ERC721__factory_1 = require("./factories/contracts/base/ERC721__factory");
Object.defineProperty(exports, "ERC721__factory", { enumerable: true, get: function () { return ERC721__factory_1.ERC721__factory; } });
var ERC721Checkpointable__factory_1 = require("./factories/contracts/base/ERC721Checkpointable__factory");
Object.defineProperty(exports, "ERC721Checkpointable__factory", { enumerable: true, get: function () { return ERC721Checkpointable__factory_1.ERC721Checkpointable__factory; } });
var ERC721Enumerable__factory_1 = require("./factories/contracts/base/ERC721Enumerable__factory");
Object.defineProperty(exports, "ERC721Enumerable__factory", { enumerable: true, get: function () { return ERC721Enumerable__factory_1.ERC721Enumerable__factory; } });
var IProxyRegistry__factory_1 = require("./factories/contracts/external/opensea/IProxyRegistry__factory");
Object.defineProperty(exports, "IProxyRegistry__factory", { enumerable: true, get: function () { return IProxyRegistry__factory_1.IProxyRegistry__factory; } });
var MojoGovernor__factory_1 = require("./factories/contracts/governance/MojoGovernor__factory");
Object.defineProperty(exports, "MojoGovernor__factory", { enumerable: true, get: function () { return MojoGovernor__factory_1.MojoGovernor__factory; } });
var MojosDAOExecutor__factory_1 = require("./factories/contracts/governance/MojosDAOExecutor__factory");
Object.defineProperty(exports, "MojosDAOExecutor__factory", { enumerable: true, get: function () { return MojosDAOExecutor__factory_1.MojosDAOExecutor__factory; } });
var IMojosDAOExecutor__factory_1 = require("./factories/contracts/governance/MojosDAOInterfaces.sol/IMojosDAOExecutor__factory");
Object.defineProperty(exports, "IMojosDAOExecutor__factory", { enumerable: true, get: function () { return IMojosDAOExecutor__factory_1.IMojosDAOExecutor__factory; } });
var MojosDAOEvents__factory_1 = require("./factories/contracts/governance/MojosDAOInterfaces.sol/MojosDAOEvents__factory");
Object.defineProperty(exports, "MojosDAOEvents__factory", { enumerable: true, get: function () { return MojosDAOEvents__factory_1.MojosDAOEvents__factory; } });
var MojosDAOProxyStorage__factory_1 = require("./factories/contracts/governance/MojosDAOInterfaces.sol/MojosDAOProxyStorage__factory");
Object.defineProperty(exports, "MojosDAOProxyStorage__factory", { enumerable: true, get: function () { return MojosDAOProxyStorage__factory_1.MojosDAOProxyStorage__factory; } });
var MojosDAOStorageV1__factory_1 = require("./factories/contracts/governance/MojosDAOInterfaces.sol/MojosDAOStorageV1__factory");
Object.defineProperty(exports, "MojosDAOStorageV1__factory", { enumerable: true, get: function () { return MojosDAOStorageV1__factory_1.MojosDAOStorageV1__factory; } });
var MojosTokenLike__factory_1 = require("./factories/contracts/governance/MojosDAOInterfaces.sol/MojosTokenLike__factory");
Object.defineProperty(exports, "MojosTokenLike__factory", { enumerable: true, get: function () { return MojosTokenLike__factory_1.MojosTokenLike__factory; } });
var MojosDAOLogicV1__factory_1 = require("./factories/contracts/governance/MojosDAOLogicV1__factory");
Object.defineProperty(exports, "MojosDAOLogicV1__factory", { enumerable: true, get: function () { return MojosDAOLogicV1__factory_1.MojosDAOLogicV1__factory; } });
var MojosDAOProxy__factory_1 = require("./factories/contracts/governance/MojosDAOProxy__factory");
Object.defineProperty(exports, "MojosDAOProxy__factory", { enumerable: true, get: function () { return MojosDAOProxy__factory_1.MojosDAOProxy__factory; } });
var ILayerZeroEndpoint__factory_1 = require("./factories/contracts/interfaces/ILayerZeroEndpoint__factory");
Object.defineProperty(exports, "ILayerZeroEndpoint__factory", { enumerable: true, get: function () { return ILayerZeroEndpoint__factory_1.ILayerZeroEndpoint__factory; } });
var ILayerZeroReceiver__factory_1 = require("./factories/contracts/interfaces/ILayerZeroReceiver__factory");
Object.defineProperty(exports, "ILayerZeroReceiver__factory", { enumerable: true, get: function () { return ILayerZeroReceiver__factory_1.ILayerZeroReceiver__factory; } });
var ILayerZeroUserApplicationConfig__factory_1 = require("./factories/contracts/interfaces/ILayerZeroUserApplicationConfig__factory");
Object.defineProperty(exports, "ILayerZeroUserApplicationConfig__factory", { enumerable: true, get: function () { return ILayerZeroUserApplicationConfig__factory_1.ILayerZeroUserApplicationConfig__factory; } });
var IMojosAuctionHouse__factory_1 = require("./factories/contracts/interfaces/IMojosAuctionHouse__factory");
Object.defineProperty(exports, "IMojosAuctionHouse__factory", { enumerable: true, get: function () { return IMojosAuctionHouse__factory_1.IMojosAuctionHouse__factory; } });
var IMojosDescriptor__factory_1 = require("./factories/contracts/interfaces/IMojosDescriptor__factory");
Object.defineProperty(exports, "IMojosDescriptor__factory", { enumerable: true, get: function () { return IMojosDescriptor__factory_1.IMojosDescriptor__factory; } });
var IMojosSeeder__factory_1 = require("./factories/contracts/interfaces/IMojosSeeder__factory");
Object.defineProperty(exports, "IMojosSeeder__factory", { enumerable: true, get: function () { return IMojosSeeder__factory_1.IMojosSeeder__factory; } });
var IMojosToken__factory_1 = require("./factories/contracts/interfaces/IMojosToken__factory");
Object.defineProperty(exports, "IMojosToken__factory", { enumerable: true, get: function () { return IMojosToken__factory_1.IMojosToken__factory; } });
var IWETH__factory_1 = require("./factories/contracts/interfaces/IWETH__factory");
Object.defineProperty(exports, "IWETH__factory", { enumerable: true, get: function () { return IWETH__factory_1.IWETH__factory; } });
var LzApp__factory_1 = require("./factories/contracts/LzApp__factory");
Object.defineProperty(exports, "LzApp__factory", { enumerable: true, get: function () { return LzApp__factory_1.LzApp__factory; } });
var MojosAuctionHouse__factory_1 = require("./factories/contracts/MojosAuctionHouse__factory");
Object.defineProperty(exports, "MojosAuctionHouse__factory", { enumerable: true, get: function () { return MojosAuctionHouse__factory_1.MojosAuctionHouse__factory; } });
var MojosDescriptor__factory_1 = require("./factories/contracts/MojosDescriptor__factory");
Object.defineProperty(exports, "MojosDescriptor__factory", { enumerable: true, get: function () { return MojosDescriptor__factory_1.MojosDescriptor__factory; } });
var MojosSeeder__factory_1 = require("./factories/contracts/MojosSeeder__factory");
Object.defineProperty(exports, "MojosSeeder__factory", { enumerable: true, get: function () { return MojosSeeder__factory_1.MojosSeeder__factory; } });
var MojosToken__factory_1 = require("./factories/contracts/MojosToken__factory");
Object.defineProperty(exports, "MojosToken__factory", { enumerable: true, get: function () { return MojosToken__factory_1.MojosToken__factory; } });
var NonblockingLzApp__factory_1 = require("./factories/contracts/NonBlockingLzApp.sol/NonblockingLzApp__factory");
Object.defineProperty(exports, "NonblockingLzApp__factory", { enumerable: true, get: function () { return NonblockingLzApp__factory_1.NonblockingLzApp__factory; } });
var MojosAuctionHouseProxy__factory_1 = require("./factories/contracts/proxies/MojosAuctionHouseProxy__factory");
Object.defineProperty(exports, "MojosAuctionHouseProxy__factory", { enumerable: true, get: function () { return MojosAuctionHouseProxy__factory_1.MojosAuctionHouseProxy__factory; } });
var MojosAuctionHouseProxyAdmin__factory_1 = require("./factories/contracts/proxies/MojosAuctionHouseProxyAdmin__factory");
Object.defineProperty(exports, "MojosAuctionHouseProxyAdmin__factory", { enumerable: true, get: function () { return MojosAuctionHouseProxyAdmin__factory_1.MojosAuctionHouseProxyAdmin__factory; } });
var MaliciousBidder__factory_1 = require("./factories/contracts/test/MaliciousBidder__factory");
Object.defineProperty(exports, "MaliciousBidder__factory", { enumerable: true, get: function () { return MaliciousBidder__factory_1.MaliciousBidder__factory; } });
var Administered__factory_1 = require("./factories/contracts/test/MojosDAOExecutorHarness.sol/Administered__factory");
Object.defineProperty(exports, "Administered__factory", { enumerable: true, get: function () { return Administered__factory_1.Administered__factory; } });
var MojosDAOExecutorHarness__factory_1 = require("./factories/contracts/test/MojosDAOExecutorHarness.sol/MojosDAOExecutorHarness__factory");
Object.defineProperty(exports, "MojosDAOExecutorHarness__factory", { enumerable: true, get: function () { return MojosDAOExecutorHarness__factory_1.MojosDAOExecutorHarness__factory; } });
var MojosDAOExecutorTest__factory_1 = require("./factories/contracts/test/MojosDAOExecutorHarness.sol/MojosDAOExecutorTest__factory");
Object.defineProperty(exports, "MojosDAOExecutorTest__factory", { enumerable: true, get: function () { return MojosDAOExecutorTest__factory_1.MojosDAOExecutorTest__factory; } });
var MojosDAOImmutable__factory_1 = require("./factories/contracts/test/MojosDAOImmutable__factory");
Object.defineProperty(exports, "MojosDAOImmutable__factory", { enumerable: true, get: function () { return MojosDAOImmutable__factory_1.MojosDAOImmutable__factory; } });
var MojosDAOLogicV1Harness__factory_1 = require("./factories/contracts/test/MojosDAOLogicV1Harness__factory");
Object.defineProperty(exports, "MojosDAOLogicV1Harness__factory", { enumerable: true, get: function () { return MojosDAOLogicV1Harness__factory_1.MojosDAOLogicV1Harness__factory; } });
var WETH__factory_1 = require("./factories/contracts/test/WETH__factory");
Object.defineProperty(exports, "WETH__factory", { enumerable: true, get: function () { return WETH__factory_1.WETH__factory; } });
var UniversalMojo__factory_1 = require("./factories/contracts/UniversalMojoToken.sol/UniversalMojo__factory");
Object.defineProperty(exports, "UniversalMojo__factory", { enumerable: true, get: function () { return UniversalMojo__factory_1.UniversalMojo__factory; } });
