"use strict";
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.IMojosToken__factory = void 0;
const ethers_1 = require("ethers");
const _abi = [
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "owner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "approved",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
        ],
        name: "Approval",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "owner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "operator",
                type: "address",
            },
            {
                indexed: false,
                internalType: "bool",
                name: "approved",
                type: "bool",
            },
        ],
        name: "ApprovalForAll",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [],
        name: "DescriptorLocked",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "contract IMojosDescriptor",
                name: "descriptor",
                type: "address",
            },
        ],
        name: "DescriptorUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "string",
                name: "message",
                type: "string",
            },
            {
                components: [
                    {
                        internalType: "uint48",
                        name: "background",
                        type: "uint48",
                    },
                    {
                        internalType: "uint48",
                        name: "body",
                        type: "uint48",
                    },
                    {
                        internalType: "uint48",
                        name: "bodyAccessory",
                        type: "uint48",
                    },
                    {
                        internalType: "uint48",
                        name: "face",
                        type: "uint48",
                    },
                    {
                        internalType: "uint48",
                        name: "headAccessory",
                        type: "uint48",
                    },
                ],
                indexed: false,
                internalType: "struct IMojosSeeder.Seed",
                name: "seed",
                type: "tuple",
            },
        ],
        name: "LogThis",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [],
        name: "MinterLocked",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "minter",
                type: "address",
            },
        ],
        name: "MinterUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
        ],
        name: "MojoBurned",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
            {
                components: [
                    {
                        internalType: "uint48",
                        name: "background",
                        type: "uint48",
                    },
                    {
                        internalType: "uint48",
                        name: "body",
                        type: "uint48",
                    },
                    {
                        internalType: "uint48",
                        name: "bodyAccessory",
                        type: "uint48",
                    },
                    {
                        internalType: "uint48",
                        name: "face",
                        type: "uint48",
                    },
                    {
                        internalType: "uint48",
                        name: "headAccessory",
                        type: "uint48",
                    },
                ],
                indexed: false,
                internalType: "struct IMojosSeeder.Seed",
                name: "seed",
                type: "tuple",
            },
        ],
        name: "MojoCreated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "mojosDAO",
                type: "address",
            },
        ],
        name: "MojosDAOUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint16",
                name: "_srcChainId",
                type: "uint16",
            },
            {
                indexed: false,
                internalType: "address",
                name: "_toAddress",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "_tokenId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint64",
                name: "_nonce",
                type: "uint64",
            },
        ],
        name: "ReceiveFromChain",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [],
        name: "SeederLocked",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "contract IMojosSeeder",
                name: "seeder",
                type: "address",
            },
        ],
        name: "SeederUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "_sender",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint16",
                name: "_dstChainId",
                type: "uint16",
            },
            {
                indexed: true,
                internalType: "bytes",
                name: "_toAddress",
                type: "bytes",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "_tokenId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint64",
                name: "_nonce",
                type: "uint64",
            },
        ],
        name: "SendToChain",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "from",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "to",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
        ],
        name: "Transfer",
        type: "event",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "to",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
        ],
        name: "approve",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "owner",
                type: "address",
            },
        ],
        name: "balanceOf",
        outputs: [
            {
                internalType: "uint256",
                name: "balance",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
        ],
        name: "burn",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
        ],
        name: "dataURI",
        outputs: [
            {
                internalType: "string",
                name: "",
                type: "string",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "_dstChainId",
                type: "uint16",
            },
            {
                internalType: "bytes",
                name: "_toAddress",
                type: "bytes",
            },
            {
                internalType: "uint256",
                name: "_tokenId",
                type: "uint256",
            },
            {
                internalType: "bool",
                name: "_useZro",
                type: "bool",
            },
            {
                internalType: "bytes",
                name: "_adapterParams",
                type: "bytes",
            },
        ],
        name: "estimateSendFee",
        outputs: [
            {
                internalType: "uint256",
                name: "nativeFee",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "zroFee",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
        ],
        name: "getApproved",
        outputs: [
            {
                internalType: "address",
                name: "operator",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "owner",
                type: "address",
            },
            {
                internalType: "address",
                name: "operator",
                type: "address",
            },
        ],
        name: "isApprovedForAll",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "lockDescriptor",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "lockMinter",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "lockSeeder",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "mint",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
        ],
        name: "ownerOf",
        outputs: [
            {
                internalType: "address",
                name: "owner",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "from",
                type: "address",
            },
            {
                internalType: "address",
                name: "to",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
        ],
        name: "safeTransferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "from",
                type: "address",
            },
            {
                internalType: "address",
                name: "to",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
            {
                internalType: "bytes",
                name: "data",
                type: "bytes",
            },
        ],
        name: "safeTransferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "_dstChainId",
                type: "uint16",
            },
            {
                internalType: "bytes",
                name: "_toAddress",
                type: "bytes",
            },
            {
                internalType: "uint256",
                name: "_tokenId",
                type: "uint256",
            },
            {
                internalType: "address payable",
                name: "_refundAddress",
                type: "address",
            },
            {
                internalType: "address",
                name: "_zroPaymentAddress",
                type: "address",
            },
            {
                internalType: "bytes",
                name: "_adapterParam",
                type: "bytes",
            },
        ],
        name: "send",
        outputs: [],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_from",
                type: "address",
            },
            {
                internalType: "uint16",
                name: "_dstChainId",
                type: "uint16",
            },
            {
                internalType: "bytes",
                name: "_toAddress",
                type: "bytes",
            },
            {
                internalType: "uint256",
                name: "_tokenId",
                type: "uint256",
            },
            {
                internalType: "address payable",
                name: "_refundAddress",
                type: "address",
            },
            {
                internalType: "address",
                name: "_zroPaymentAddress",
                type: "address",
            },
            {
                internalType: "bytes",
                name: "_adapterParam",
                type: "bytes",
            },
        ],
        name: "sendFrom",
        outputs: [],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "operator",
                type: "address",
            },
            {
                internalType: "bool",
                name: "_approved",
                type: "bool",
            },
        ],
        name: "setApprovalForAll",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract IMojosDescriptor",
                name: "descriptor",
                type: "address",
            },
        ],
        name: "setDescriptor",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "minter",
                type: "address",
            },
        ],
        name: "setMinter",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "mojosDAO",
                type: "address",
            },
        ],
        name: "setMojosDAO",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract IMojosSeeder",
                name: "seeder",
                type: "address",
            },
        ],
        name: "setSeeder",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes4",
                name: "interfaceId",
                type: "bytes4",
            },
        ],
        name: "supportsInterface",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "from",
                type: "address",
            },
            {
                internalType: "address",
                name: "to",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
        ],
        name: "transferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
];
class IMojosToken__factory {
    static createInterface() {
        return new ethers_1.utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    }
}
exports.IMojosToken__factory = IMojosToken__factory;
IMojosToken__factory.abi = _abi;
