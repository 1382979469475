"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MojosAuctionHouseProxyAdmin__factory = exports.MojosAuctionHouseProxy__factory = void 0;
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
var MojosAuctionHouseProxy__factory_1 = require("./MojosAuctionHouseProxy__factory");
Object.defineProperty(exports, "MojosAuctionHouseProxy__factory", { enumerable: true, get: function () { return MojosAuctionHouseProxy__factory_1.MojosAuctionHouseProxy__factory; } });
var MojosAuctionHouseProxyAdmin__factory_1 = require("./MojosAuctionHouseProxyAdmin__factory");
Object.defineProperty(exports, "MojosAuctionHouseProxyAdmin__factory", { enumerable: true, get: function () { return MojosAuctionHouseProxyAdmin__factory_1.MojosAuctionHouseProxyAdmin__factory; } });
