"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WETH__factory = exports.MojosDAOLogicV1Harness__factory = exports.MojosDAOImmutable__factory = exports.MaliciousBidder__factory = exports.mojosDaoExecutorHarnessSol = void 0;
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
exports.mojosDaoExecutorHarnessSol = __importStar(require("./MojosDAOExecutorHarness.sol"));
var MaliciousBidder__factory_1 = require("./MaliciousBidder__factory");
Object.defineProperty(exports, "MaliciousBidder__factory", { enumerable: true, get: function () { return MaliciousBidder__factory_1.MaliciousBidder__factory; } });
var MojosDAOImmutable__factory_1 = require("./MojosDAOImmutable__factory");
Object.defineProperty(exports, "MojosDAOImmutable__factory", { enumerable: true, get: function () { return MojosDAOImmutable__factory_1.MojosDAOImmutable__factory; } });
var MojosDAOLogicV1Harness__factory_1 = require("./MojosDAOLogicV1Harness__factory");
Object.defineProperty(exports, "MojosDAOLogicV1Harness__factory", { enumerable: true, get: function () { return MojosDAOLogicV1Harness__factory_1.MojosDAOLogicV1Harness__factory; } });
var WETH__factory_1 = require("./WETH__factory");
Object.defineProperty(exports, "WETH__factory", { enumerable: true, get: function () { return WETH__factory_1.WETH__factory; } });
