"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getContractsForChainOrThrow = void 0;
const contracts_1 = require("@mojos/contracts");
const addresses_1 = require("./addresses");
/**
 * Get contract instances that target the Ethereum mainnet
 * or a supported testnet. Throws if there are no known contracts
 * deployed on the corresponding chain.
 * @param chainId The desired chain id
 * @param signerOrProvider The ethers v5 signer or provider
 */
const getContractsForChainOrThrow = (chainId, signerOrProvider) => {
    const addresses = (0, addresses_1.getContractAddressesForChainOrThrow)(chainId);
    return {
        mojosTokenContract: contracts_1.MojosTokenFactory.connect(addresses.mojosToken, signerOrProvider),
        mojosAuctionHouseContract: contracts_1.MojosAuctionHouseFactory.connect(addresses.mojosAuctionHouseProxy, signerOrProvider),
        mojosDescriptorContract: contracts_1.MojosDescriptorFactory.connect(addresses.mojosDescriptor, signerOrProvider),
        mojosSeederContract: contracts_1.MojosSeederFactory.connect(addresses.mojosSeeder, signerOrProvider),
        mojosDaoContract: contracts_1.MojosDaoLogicV1Factory.connect(addresses.mojosDAOProxy, signerOrProvider),
    };
};
exports.getContractsForChainOrThrow = getContractsForChainOrThrow;
