"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ERC1967Upgrade__factory = exports.ERC1967Proxy__factory = void 0;
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
var ERC1967Proxy__factory_1 = require("./ERC1967Proxy__factory");
Object.defineProperty(exports, "ERC1967Proxy__factory", { enumerable: true, get: function () { return ERC1967Proxy__factory_1.ERC1967Proxy__factory; } });
var ERC1967Upgrade__factory_1 = require("./ERC1967Upgrade__factory");
Object.defineProperty(exports, "ERC1967Upgrade__factory", { enumerable: true, get: function () { return ERC1967Upgrade__factory_1.ERC1967Upgrade__factory; } });
